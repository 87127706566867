import {useColorMode} from "@chakra-ui/react";
import {FC, useContext, useState, useEffect} from "react";
import {Routes, Route, Outlet, useSearchParams} from "react-router-dom";
import {axiosInstance} from "../api/http";
import {
    Annex,
    Contract,
    Dashboard,
    Finance,
    Internet,
    InternetNode,
    Login,
    MobileTelephony,
    Package,
    SpeedTest,
    Telephony,
    Television,
} from "../pages";
import {
    CustomedSpinner
} from '../shared/components';
import Hosting from "../pages/Hosting";
import {Header} from "../shared/components/Header";
import {LoginStateContext} from "../shared/components/LoginState";
import {endpoints} from "../api/endpoints";
import {useFirm} from "../hooks/useFirm";

export const ContentRouter: FC = () => {
    const {state} = useContext(LoginStateContext);

    if (state.status === "LOGGED_IN" || "LOGGED_IN_WITH_EXISTING_TOKEN") {
        if (axiosInstance.defaults.headers.common["Authorization"])
            return (
                <>
                    <Header/>
                    <Outlet/>
                </>
            );
    }

    return null;
};

export const MainRouter: FC = () => {
    const {colorMode, toggleColorMode} = useColorMode(),
        {id, name} = useFirm().data,
        searchParams = useSearchParams(),
        params = Object.fromEntries(searchParams[0].entries()),
        {dispatch} = useContext(LoginStateContext);
    const [isLoading, setIsLoading] = useState(true);
    // let loading = !!Object.keys(params).length;
    // let loading = true;

    useEffect(() => {
        if (colorMode === "dark") {
            toggleColorMode();
        }
    }, [colorMode, toggleColorMode]);

    useEffect(() => {
        setIsLoading(true);
        if (name) {
            document.title = `EBOK - ${name}`;
        }
        if (id) {
            const head = document.head,
                link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = 'i/firm' + id + '/theme.css';
            head.appendChild(link);
            setIsLoading(false);
            return () => {
                head.removeChild(link);
            }
        }
    }, [name, id]);

    Object.keys(params).map((key) => {
        if (key && !params[key]) {
            const loginUser = async () => {
                await fetch(endpoints.mmsLogin.replaceAll("{id}", key)).then((data) => {
                    if (data.status === 200) {
                        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${key}`;
                        dispatch({type: "LOGGED_IN", payload: {token: key}});
                    }
                });
            };
            loginUser();
        }
    });

    return isLoading ? (
        <CustomedSpinner/>
    ) : (
        <Routes>
            <Route path={"login"} element={<Login/>}/>
            <Route path={"/"} element={<ContentRouter/>}>
                <Route index element={<Dashboard/>}/>
                <Route path={"payment"} element={<Outlet/>}>
                    <Route index element={<Finance/>}/>
                    <Route path={"contract/:id"} element={<Contract/>}/>
                    <Route path={"annex/:id"} element={<Annex/>}/>
                </Route>
                <Route path={"internet"} element={<Outlet/>}>
                    <Route index element={<Internet/>}/>
                    <Route path={"node/:id"} element={<InternetNode/>}/>
                </Route>
                <Route path={"telephony"} element={<Telephony/>}/>
                <Route path={"mobile"} element={<MobileTelephony/>}/>
                <Route path={"hosting"} element={<Hosting/>}/>
                <Route path={"television"} element={<Outlet/>}>
                    <Route index element={<Television/>}/>
                    <Route path={"package/:id"} element={<Package/>}/>
                </Route>
                <Route path={"speedtest"} element={<SpeedTest/>}/>
            </Route>
            <Route path={"*"} element={<div>404</div>}/>
        </Routes>
    );
};
