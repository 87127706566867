import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    CloseButton,
    Container,
    Flex,
    Image,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useDisclosure,
} from "@chakra-ui/react";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

import { useFirm } from "../../hooks/useFirm";

import sparksImage from "../../img/services/Sparks.png";
import { RegulationModal, CustomedInput } from "../../shared/components";

import { CustomLogo } from "../../shared/components/Header/chakra";

import './style.css';
import { GlobalDamagesCard } from "../../shared/components/Cards/GlobalDamages";
import { useGlobalDamages } from "../../hooks/useGlobalDamages";

interface IUserCredentials {
    login: string;
    pass: string;
}

const initialState = {
    login: "",
    pass: "",
};

export const Login: FC = () => {
    const { id, phone, url_eboklogo, firmsite } = useFirm().data,
        { isLoading, loginUser, isError, errors } = useAuth(),
        [inputs, setInputs] = useState<IUserCredentials>(initialState),
        //  [isRememberMe, setIsRememberMe] = useState<boolean>(false),
        [isRegulationOpened, setRegulationOpened] = useState<boolean>(false),
        { isOpen, onOpen, onClose } = useDisclosure(),
        { totalDamagesLength } = useGlobalDamages().data,

        navigate = useNavigate(),

        handleInputChange = (key: string, value: string) => {
            const fixedValue = value.trim();

            setInputs((oldState) => {
                const newState = { ...oldState, [key]: fixedValue };
                return newState;
            });
        },

        { handleSubmit } = useForm(),

        onSubmit = async () => {
            if (inputs) {
                loginUser(inputs);
            }
        },

        onOpenRegulation = async () => {
            setRegulationOpened(!isRegulationOpened);
        };

    useEffect(() => {
        if (isError) {
            onOpen();
        }
    }, [isError, onOpen]);

    useEffect(() => {
        if (isRegulationOpened) {
            console.log(' is regulation opened: ' + isRegulationOpened )
        }
    }, [isRegulationOpened]);

    return (
        <Flex flexDirection="column" width="100%" height="100%" justifyContent="center" alignItems="center" top={["0", "0", "0", "50%"]} left={["0", "0", "0", "50%"]}
            position={["relative", "relative", "relative", "absolute"]} transform={["", "", "", "translateY(-50%) translateX(-50%)"]}>
            <Stack flexDir="column" mt="5" mb="5" justifyContent="center" alignItems="center" width="100%">
                <Flex flexDir={["column", "column", "row"]} justifyContent="left" alignItems="center" minW={["100%", "100%", "840px"]} m={["10px 0 0 0", "10px 0 0 0", "0 0 20px 0", "0 0 20px 0"]}>
                    <Box width={{ base: "75%", sm: "75%", md: "25%" }} justifyContent={["center", "center", "none"]} m={["0 37px", "0 37px", "0 37px", "0"]} display={["flex", "flex", "inline-block"]} >
                        <CustomLogo src={url_eboklogo} onClick={() => {
                            window.open(firmsite, '_blank');
                            // navigate(firmsite.replace('http:', '').replace('https:', ''), {replace: true});
                        }} />
                    </Box>
                    <Box display={["block", "block", "inline-block"]} style={{ width: "75%", padding: "5px" }} textAlign={["center", "center", "right"]} m={["0 37px", "0 37px", "0 37px", "0"]}>
                        <Text fontSize="24px">
                            Elektroniczne Biuro Obsługi Klienta
                        </Text>
                    </Box>
                </Flex>
                <Flex flexDir={["column-reverse", "column-reverse", "row"]} backgroundColor={["none", "none", "white"]} justifyContent="center" alignItems="center" minW={["100%", "100%", "750px"]}
                    minH={["100%", "100%", "600px"]} m={["0px", "0px", "60px 0px"]}>
                    <Box minW={{ md: "450px" }} width="100%">
                        <Stack spacing={4} p="1rem" backgroundColor={["none", "none", "white"]} boxShadow={["none", "none", "md"]} borderLeftRadius="10px" height={["100%", "100%", "600px"]}
                            padding={["0 40px", "0 40px", "40px 80px 0 30px"]}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Text fontSize="24px" color="darkPrimary" textAlign={["center", "center", "left"]}>
                                    Logowanie do EBOK
                                </Text>
                                {isOpen && (
                                    <Alert status="error" borderRadius="8px" mb="10px">
                                        <AlertIcon />
                                        <AlertTitle mr={2}>Błąd logowania!</AlertTitle>
                                        <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
                                    </Alert>
                                )}
                                <Box pt={["20px", "20px", "0px"]}>
                                    <CustomedInput autoFocus label="Numer klienta" placeholder="Podaj numer klienta" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        handleInputChange("login", event.target.value);
                                    }} type="text" margin={["0px", "0px", "0px 15px 0px 0px"]} mb="10px" labelStyle={{ fontWeight: "bold" }} />
                                    <CustomedInput label="Hasło" placeholder="Podaj swoje hasło" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        handleInputChange("pass", event.target.value);
                                    }} type="password" margin={["0px", "0px", "0px 15px 0px 0px"]} mb="10px" labelStyle={{ fontWeight: "bold" }} />
                                </Box>
                                <Flex justifyContent={["center", "center", "start"]}>
                                    <Button onClick={onSubmit} loadingText="Logowanie..." isLoading={isLoading} isDisabled={inputs.login.trim().length < 1} backgroundColor="lightPrimary"
                                        _hover={{ backgroundColor: "lighterPrimary" }} color="white" maxW="160px" type="submit">
                                        Zaloguj do EBOK
                                    </Button>
                                </Flex>
                                <Flex style={{ marginTop: "auto", marginBottom: "20px" }} pt={["10px", "10px", "0"]} pl={["0", "0", "30px"]} flexWrap={["wrap", "wrap", "nowrap"]} flexDirection="row"
                                    width="100%" justifyContent={["center", "center", "flex-end"]}>
                                    <Text fontSize="12px" maxW="240px" mt="auto">
                                        FORWEB © 2024
                                        <span style={{
                                            cursor: "pointer",
                                            paddingLeft: "10px",
                                            color: "var(--chakra-colors-lightPrimary)"
                                        }} onClick={onOpenRegulation}>
                                            Regulamin
                                        </span>
                                        {isRegulationOpened &&
                                            <Container maxWidth={"container.xl"} flexDir={"column"}>
                                                <RegulationModal modalHeader={'Regulamin'} noButton={true} onCloseModal={onOpenRegulation} />
                                            </Container>
                                        }
                                    </Text>
                                </Flex>
                                {
                                    totalDamagesLength ?
                                        <Box display={["block", "block", "block"]} boxShadow={["md", "md", "md"]} borderLeftRadius="10px" maxH='50%' maxW='340px' overflowY='auto' >
                                            <GlobalDamagesCard />
                                        </Box>
                                        : null
                                }
                            </form>
                        </Stack>
                    </Box>
                    <Box maxW={{ md: "390px" }} display={["none", "none", "block"]} width="100%" mt="0 !important">
                        <Stack spacing={4} p="1rem" backgroundColor="darkerPrimary" boxShadow="md" borderRadius="10px" height="600px">
                            <Box padding="40px 40px 0 30px">
                                <Box>
                                    <Text fontSize="24px" fontWeight="bold" color="lightPrimary">
                                        Jak się zalogować?
                                    </Text>
                                    <Text fontSize="14px" color="lightGray" textTransform="uppercase">
                                        TO BARDZO PROSTE!
                                    </Text>
                                </Box>
                                <Text fontSize="16px" fontWeight="bold" lineHeight="20px" color="lighterGray" paddingTop="38px">
                                    Twoim loginem jest numer klienta.
                                </Text>
                                <Text fontSize="14px" color="lighterGray">
                                    Login oraz hasło do pierwszego logowania znajdziesz w Umowie.
                                </Text>
                                <Text fontSize="16px" fontWeight="bold" color="lighterGray" paddingTop="38px">
                                    Posiadając konto EBOK masz dostęp do:
                                    <UnorderedList pt="20px">
                                        <ListItem color="lightPrimary">
                                            <Text color="white" fontSize="14px" fontWeight="normal">
                                                swoich faktur
                                            </Text>
                                        </ListItem>
                                        <ListItem color="lightPrimary">
                                            <Text color="white" fontSize="14px" fontWeight="normal">
                                                szczegółów wykupionych usług
                                            </Text>
                                        </ListItem>
                                        <ListItem color="lightPrimary">
                                            <Text color="white" fontSize="14px" fontWeight="normal">
                                                dokumentów oraz wniosków
                                            </Text>
                                        </ListItem>
                                    </UnorderedList>
                                </Text>
                            </Box>
                            <Flex style={{ marginTop: "auto" }} flexDir="row" justify="space-between" pl="30px">
                                <Text fontSize="12px" color="white" maxW="240px" mt="25px" style={{
                                    textAlign: "justify"
                                }}>
                                    Jeśli dotychczas nie korzystałeś z EBOK, nie pamiętasz numeru klienta lub zagubiłeś otrzymane hasło, możesz skontaktować się z numerem infolinii {phone?.split(",")[0]}
                                </Text>
                                <Image src={sparksImage} height="102px" margin="10px" />
                            </Flex>
                        </Stack>
                    </Box>
                </Flex>
            </Stack>
        </Flex>
    );
};
