export const createBankAccountNumber = (account: string = "") =>
  account &&
  account.substring(0, 2) +
    " " +
    account
      .substring(2)
      .match(/.{1,4}/g)!
      .join(" ");

export const timestampToDateString = (
  timestamp: number,
  join: string = "."
) => {
  const date = new Date(timestamp);

  return [
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1,
    date.getFullYear(),
  ].join(join);
};

export const formatClock = (date: Date | number, withSeconds?: boolean): string => {
  if (!withSeconds) withSeconds = false;
  if (!(date instanceof Date)) {
      date = new Date(date)
  }
  return [date.getHours() < 10 ? '0' + date.getHours() : date.getHours(), date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()].join(":")
};

export const daysDifference = (date: string) => {
  const difference = new Date().getTime() - new Date(date).getTime();
  return Math.floor(difference / (1000 * 3600 * 24));
};

export const monthsDifference = (date: string) => {
  if (!date) {
    return 0;
  }
  const dateFrom = new Date(),
      dateTo = new Date(date),
      difference = dateTo.getMonth() - dateFrom.getMonth() +
          (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  return difference;
};

export const restructureDate = (date: string = "", join: string = ".") => {
  if (date) {
    return date.split(" ")[0].split("-").reverse().join(join);
  }
  return "Brak danych";
};

export const formatTransfer = (transfer: number = 0) => {
  if (transfer / (1024 * 1024) > 1) {
    return (transfer / (1024 * 1024)).toFixed(2) + " GB";
  } else if (transfer) {
    return (transfer / 1024).toFixed(2) + " MB";
  }
  return "Brak";
};

export const capitalizeFirstLetter = (string: string = "") => {
  return string.length ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};
export interface IOptions {
  [k: string]: string;
}

export const generateOptions = (options: IOptions, skip?: number) =>
  options &&
  Object.entries(options)
    .slice(skip || 0)
    .map(([key, value]) => (
      <option key={key + value} value={key}>
        {capitalizeFirstLetter(value)}
      </option>
    ));

export const generateOption = (label: string, value: string) => (
  <option key={label + value} value={value}>
    {capitalizeFirstLetter(label)}
  </option>
);

export const secondsToMinutes = (seconds: number) => {
  if (seconds > 0) {
    return (
      (seconds > 60 ? Math.floor(seconds / 60) : "00") +
      ":" +
      (seconds > 10 ? seconds % 60 : `0${seconds}`) +
      " min"
    );
  }
  return "00:00 min";
};

export const formatTime = (seconds: number) => {
  if (seconds > 0) {
    let timestring = "";

    if (seconds / (60 * 60) > 1) {
      timestring += `${Math.floor(seconds / (60 * 60))}g `;
    }
    if ((seconds % (60 * 60)) / 60 >= 1) {
      timestring += `${Math.floor((seconds % (60 * 60)) / 60)}min `;
    }
    if (seconds % 60) {
      timestring += `${Math.floor(seconds % 60)}s `;
    }
    return timestring;
  }
  return "Brak";
};

export const formatPhoneNumber = (phone: string) => {
  // if (phone.startsWith("48")) {
  //   return phone.slice(2).replace(/\d{3}(?=.)/g, "$& ");
  // } else if (phone.length === 9) {
  //   return phone.replace(/\d{3}(?=.)/g, "$& ");
  // } else if (phone.startsWith("0048")) {
  //   return phone.slice(4).replace(/\d{3}(?=.)/g, "$& ");
  // }

  if (phone.startsWith("48")) {
    return phone.slice(2);
  } else if (phone.startsWith("0048")) {
    return phone.slice(4);
  }
  return phone;
};

export const afterMonths = (months: number) => {
  const result = new Date();
  result.setMonth(result.getMonth() + months);
  return result.toISOString().split(" ")[0].split("T")[0];
};
