import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Container,
  Fade,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import bannerImage from "../../../src/img/bannerSettlements/rozliczenia_top.jpg";
import { useAnnexDetails } from "../../hooks/useAnnexDetails";
import { useAnnexSms } from "../../hooks/useAnnexSms";
import { useUserData } from "../../hooks/useUserData";
import {
  Banner,
  Card,
  Detail,
  StatusBadge,
  ContactFormModal,
  CustomedButton,
  CustomedSpinner,
  CustomedInput,
  PageHeader,
} from "../../shared/components";
import { BannerClass } from "../../shared/components/Banner/utils";
import { capitalizeFirstLetter, restructureDate } from "../../shared/utils";
import { env } from "../../env";

interface IAnnexSms {
  pin?: string;
}

export const Annex: FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [firstSmsSend, setFirstSmsSend] = useState(false);
  const [inputs, setInputs] = useState<IAnnexSms>({});

  let { id } = useParams();

  const user = useUserData();
  const bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? user.data?.usercode : user.data?.userno);

  const annexDetails = useAnnexDetails(id || "0");
  const { date, name, status, status_desc, html } = annexDetails?.data;

  const { isLoading, isSuccess, isError, errors, action, annexSms, resetForm } =
    useAnnexSms(id || "0");

  const handleInputChange = (key: string, value: string) => {
    setInputs((oldState) => {
      const newState = { ...oldState, [key]: value };
      return newState;
    });
  };

  const sendSMS = (withPin?: boolean) => {
    annexSms(withPin ? inputs : {});
  };

  useEffect(() => {
    if (firstSmsSend === false && isSuccess === true) {
      setFirstSmsSend(true);
    }
  }, [firstSmsSend, isSuccess]);

  useEffect(() => {
    if (annexDetails.isLoading && !annexDetails.data) {
      setIsPageLoading(true);
    } else {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 300);
    }
  }, [annexDetails]);

  if (isPageLoading) {
    return <CustomedSpinner />;
  }

  return (
    <>
      <Banner
        extraContent={
          <ContactFormModal
            button={
              <CustomedButton
                centered={true}
                fontSize="22px"
                height="50px"
                label="Przedłuż umowę"
              />
            }
            action="contract"
          />
        }
        bannerImage={bannerImage}
        {...bannerDetails}
      />
      <Fade in={true}>
        <Container maxWidth={"container.xl"} flexDir={"column"}>
          <PageHeader title="Aneks" buttonText="Finanse" />
          <Grid templateColumns="repeat(2, 1fr)" gap={30} mb="20px">
            <GridItem colSpan={[2, 1, 1]}>
              <Card title="Informacje">
                <Flex flexWrap={"wrap"}>
                  <Flex>
                    <Detail
                      title="Dokument"
                      textSize="24px"
                      color="black"
                      m={["0 70px 20px 0", "0 70px 20px 0", "0 70px 0px 0"]}
                    >
                      {name}
                    </Detail>
                  </Flex>
                  <Detail
                    title="Data wystawienia"
                    textSize="24px"
                    color="black"
                    m={["0 70px 20px 0", "0 70px 20px 0", "0 70px 0px 0"]}
                  >
                    {restructureDate(date)}
                  </Detail>
                  <Flex flexDir="column" mb={["20px", "20px", "0px"]}>
                    <Text
                      fontSize="14px"
                      color="lightGray"
                      textTransform="uppercase"
                    >
                      Status:
                    </Text>
                    {status ? (
                      <StatusBadge
                        status="active"
                        label={capitalizeFirstLetter(status_desc || "Aktywna")}
                      />
                    ) : (
                      <StatusBadge
                        status="inactive"
                        label={capitalizeFirstLetter(
                          status_desc || "Niepodpisana"
                        )}
                      />
                    )}
                  </Flex>
                </Flex>
              </Card>
            </GridItem>
            <GridItem colSpan={[2, 1, 1]}>
              <Card title="Potwierdzenie SMS">
                <Text fontSize="18px" color="black" fontWeight={600}>
                  {status
                    ? "Ten aneks został już podpisany."
                    : "Zapoznaj się z aneksem i wpisz kod SMS aby go potwierdzić."}
                </Text>

                {isError && (
                  <Alert status="error" borderRadius="8px" my="10px">
                    <AlertIcon />
                    <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                    <AlertDescription mr={"15px"}>
                      {errors.join(" ")}
                    </AlertDescription>
                    <CloseButton
                      position="absolute"
                      right="8px"
                      top="8px"
                      onClick={resetForm}
                    />
                  </Alert>
                )}

                {isSuccess && action === "sms_send" && (
                  <Alert status="success" borderRadius="8px" my="10px">
                    <AlertIcon />
                    <AlertDescription mr={"15px"}>
                      SMS został wysłany.
                    </AlertDescription>
                    <CloseButton
                      position="absolute"
                      right="8px"
                      top="8px"
                      onClick={resetForm}
                    />
                  </Alert>
                )}

                {status ? null : isSuccess && action === "sign_confirmed" ? (
                  <Alert status="success" borderRadius="8px" my="10px">
                    <AlertIcon />
                    <AlertDescription mr={"15px"}>
                      Podpisanie aneksu powiodło się
                    </AlertDescription>
                  </Alert>
                ) : (
                  <Flex
                    align="center"
                    mt={["0px", "0px", "20px"]}
                    flexDir={["column", "column", "row"]}
                    flexWrap="wrap"
                  >
                    <Box
                      width={["100%", "100%", "200px"]}
                      mr={["0px", "0px", "20px"]}
                    >
                      <CustomedInput
                        label="Kod SMS"
                        width={["100%", "100%", "200px"]}
                        isDisabled={!firstSmsSend}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          handleInputChange("pin", event.target.value);
                        }}
                      />
                    </Box>
                    <CustomedButton
                      label="Potwierdź"
                      backgroundColor="lightPrimary"
                      alignSelf="flex-end"
                      height="35px"
                      width={["100%", "100%", "auto"]}
                      mr={["0px", "0px", "20px", "20px"]}
                      mt="10px"
                      disabled={
                        !firstSmsSend ||
                        inputs.pin?.length === undefined ||
                        inputs.pin?.length < 1
                      }
                      isLoading={isLoading && action === "sms_send"}
                      onClick={() => sendSMS(true)}
                    />
                    <CustomedButton
                      label={
                        firstSmsSend ? "Wyślij kod ponownie" : "Wyślij Kod"
                      }
                      backgroundColor="lightPrimary"
                      alignSelf="flex-end"
                      height="35px"
                      width={["100%", "100%", "auto"]}
                      mt="10px"
                      onClick={() => sendSMS()}
                      isLoading={isLoading && action !== "sms_send"}
                    />
                  </Flex>
                )}
              </Card>
            </GridItem>
            <GridItem colSpan={2}>
              <Card title={"Szczegóły " + name}>
                {html ? (
                  <Box
                    display={["none", "none", "flex"]}
                    paddingTop="20px"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                ) : (
                  <Text display={["none", "none", "flex"]} textAlign="center" fontSize="16px">
                    Podgląd umowy będzie dostępny wkrótce.
                  </Text>
                )}
                <Text
                  display={["flex", "flex", "none"]}
                  textAlign="center"
                  fontSize="18px"
                >
                  Podgląd umowy jest obecnie niedostępny w wersji mobilnej.<br/>W celu zapoznania się z treścią umowy skorzystaj z EBOK na komputerze.
                </Text>
              </Card>
            </GridItem>
          </Grid>
        </Container>
      </Fade>
    </>
  );
};
