import {
    Box,
    Container,
    Fade,
    Flex,
    Grid,
    GridItem,
    Text,
} from "@chakra-ui/react";
import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {useInternetNode} from "../../hooks/useInternetNode";
import {
    Banner,
    Card,
    ContactFormModal,
    CustomedButton,
    CustomedSpinner,
    InternetConfigTable,
    InternetServiceTable,
    PageHeader,
} from "../../shared/components";
import {BannerClass} from "../../shared/components/Banner/utils";

import {IInternetConfig} from "../../shared/components/Tables/InternetConfig";
import {IInternetService} from "../../shared/components/Tables/InternetService";
import {useUserData} from "../../hooks/useUserData";
import bannerImage from "../../img/bannerHosting/hosting_top.jpg";
import { ParentalControlTable } from "../../shared/components/Tables/ParentalControl";
import { env } from "../../env";

export const InternetNode: FC = () => {
    let {id} = useParams();
    const {data, isLoading} = useInternetNode({id}),
        userData = useUserData(),

        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno);


    if (data.config && isLoading) {
        return <CustomedSpinner/>;
    }

    return (
        <>
            <Banner
                extraContent={
                    <ContactFormModal
                        button={
                            <CustomedButton
                                centered={true}
                                fontSize="22px"
                                height="50px"
                                label="Przedłuż umowę"
                            />
                        }
                        action="contract"
                    />
                }
                bannerImage={bannerImage}
                {...bannerDetails}/>
            <Fade in={true}>
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <PageHeader title={"Adres " + data.ip} buttonText="Internet"/>
                    <Grid templateColumns="repeat(2, 1fr)" gap={30} mb="20px">
                        <GridItem colSpan={2}>
                            {data.config && (
                                <Card title="Dane konfiguracyjne" mb="20px">
                                    <InternetConfigTable
                                        configs={[data.config as IInternetConfig]}
                                    />
                                </Card>
                            )}
                        </GridItem>

                        <GridItem colSpan={2}>
                            <Card title="Dane usługi" mb="20px">
                                <InternetServiceTable
                                    services={[data.service as IInternetService]}
                                />
                            </Card>
                        </GridItem>

                        {id ? 
                            <GridItem colSpan={2}>
                                <Card title="Usługa rodzicielska" mb="20px" >
                                    <ParentalControlTable id={id}/>
                                </Card>
                            </GridItem>
                            : null
                        }

                        <GridItem colSpan={[2, 2, 1, 1]}>
                            <Card title="Prędkość usługi" variant="dark" mb="20px">
                                <Flex justifyContent="space-around" flexWrap="wrap">
                                    <Box>
                                        <Text
                                            fontSize="24px"
                                            color="white"
                                            textAlign="center"
                                            m={["0px 20px", "0px 20px", "10px 0px"]}
                                        >
                                            W DZIEŃ
                                        </Text>
                                        <Box>
                                            <Text fontSize="14px" color="lighterGray">
                                                MAKSYMALNA (pobieranie/wysyłanie)
                                            </Text>
                                            <Text
                                                fontSize="24px"
                                                fontWeight={600}
                                                color="lightPrimary"
                                                textAlign="center"
                                            >
                                                {data.speed.day?.max.download || "Brak danych"} /
                                                {" " + data.speed.day?.max.upload || "Brak danych"}{" "}
                                                Mbit/s
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text
                                            fontSize="24px"
                                            color="white"
                                            textAlign="center"
                                            m={["0px 20px", "0px 20px", "10px 0px"]}
                                        >
                                            W NOCY
                                        </Text>
                                        <Box>
                                            <Text fontSize="14px" color="lighterGray">
                                                MAKSYMALNA (pobieranie/wysyłanie)
                                            </Text>
                                            <Text
                                                fontSize="24px"
                                                fontWeight={600}
                                                color="lightPrimary"
                                                textAlign="center"
                                            >
                                                {data.speed.night?.max.download || "Brak danych"} /
                                                {" " + data.speed.night?.max.upload || "Brak danych"}{" "}
                                                Mbit/s
                                            </Text>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Card>
                        </GridItem>

                        {/* <GridItem colSpan={[2, 2, 1, 1]}>
              <Card title="Usługa rodzicielska" mb="20px">
                <Flex flexDirection={["column", "column", "row"]}>
                  <FormControl
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mr={["0px", "0px", "20px"]}
                    mb="10px"
                  >
                    <FormLabel htmlFor="directMarketing">
                      Status adresu IP
                    </FormLabel>
                    <Switch
                      id="directMarketing"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleInputChange("key", event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="10px"
                  >
                    <FormLabel htmlFor="directMarketing">Internet</FormLabel>
                    <Switch
                      id="directMarketing"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleInputChange("key", event.target.value);
                      }}
                    />
                  </FormControl>
                </Flex>
                <Box>
                  <Text>W godzinach:</Text>
                  <Flex>
                    <CustomedInput type="time" />
                    <CustomedInput type="time" />
                  </Flex>
                </Box>
              </Card>
            </GridItem> */}
                    </Grid>
                </Container>
            </Fade>
        </>
    );
};
